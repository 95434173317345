<template>
  <b-card class="provider-order">
    <b-modal
      id="create-dispute-modal"
      v-model="orderHistoryProps.viewDisputeModal"
      :class="disputeObject ? 'height-auto' : null"
      :title="$t('Dispute Details')"
      :ok-title="$t('Submit')"
      ok-only
      :no-close-on-backdrop="true"
      @hidden="hideDisputeModal"
      @ok="submitServiceDispute"
    >
      <b-col
        v-if="!disputeObject"
        cols="12"
        md="12"
      >
        <b-form-group
          :label="$t('Dispute Name')"
          class="price-range-dropdown"
        >
          <v-select
            v-model="orderHistoryProps.currentDispute"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="disputeOptions"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group
          v-if="orderHistoryProps.currentDispute === 'Others'"
          :label="$t('Dispute Reason')"
          label-for="disputeReason"
        >
          <b-form-input
            id="disputeReason"
            v-model="orderHistoryProps.disputeReason"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="disputeObject"
        cols="12"
        md="12"
      >
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Dispute Name') }}:</span> {{ orderHistoryProps.disputeObject.dispute_name }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Date') }}:</span> {{ orderHistoryProps.disputeObject.created_time }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ orderHistoryProps.disputeObject.status }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments By') }}:</span> {{ orderHistoryProps.disputeObject.comments_by }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments') }}:</span> {{ orderHistoryProps.disputeObject.comments }}
        </p>
      </b-col>
    </b-modal>
    <b-row>
      <b-col
        md="4"
        class="mx-auto order-filters-position"
      >
        <b-form-group :label="$t('Filters')">
          <v-select
            v-model="orderFiltersDropDown"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderFilterOptions"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <good-data-table
          ref="ordersRef"
          :columns="orderColumns"
          :extra-params="extraFilterInput"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/provider/history/order'"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BModal, BFormGroup, BFormInput } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import vSelect from 'vue-select'
// import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BCard,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      orderFiltersDropDown: 'completed',
      extraFilterInput: '',
      orderHistoryProps: {
        viewDisputeModal: false,
        currentDispute: '',
        disputeReason: '',
        currentServiceForDispute: {},
        disputeObject: {},
      },
      orderFilterOptions: [
        {
          label: 'Completed or Cancelled',
          value: 'completed',
        },
        {
          label: 'Upcoming',
          value: 'upcoming',
        },
        {
          label: 'Others',
          value: 'current',
        },
      ],
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Delivery person was rude',
          value: 'Delivery person was rude',
        },
        {
          label: 'My Promocode Does Not Get Applied',
          value: 'My Promocode Does Not Get Applied',
        },
        {
          label: 'Delivery person picked wrong order and delivered',
          value: 'Delivery person picked wrong order and delivered',
        },
        {
          label: 'My Promocode does not get applied',
          value: 'My Promocode does not get applied',
        },
        {
          label: 'Delivery person delayed pickup',
          value: 'Delivery person delayed pickup',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
      orderColumns: [
        {
          label: 'Booking Id',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Name',
          field: 'first_name',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.provider) {
              return `${props.provider.first_name} ${props.provider.last_name}`
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'total_amount',
          rField: 'total_amount_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => `${props.currency} ${props.total_amount}`,
        },
        {
          label: 'Payment',
          field: 'payment_mode',
          useRenderer: true,
          renderer: props => props.payment_mode,
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewOrderDetails(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createOrderDispute(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    disputeObject() {
      return this.orderHistoryProps.disputeObject && this.orderHistoryProps.disputeObject.id
    },
  },
  watch: {
    orderFiltersDropDown: {
      handler(currentValue) {
        if (currentValue === 'completed') {
          this.extraFilterInput = 'completed'
        } else if (currentValue === 'upcoming') {
          this.extraFilterInput = 'type=upcoming'
        } else if (currentValue === 'current') {
          this.extraFilterInput = 'type=current'
        }
        this.$refs.ordersRef.loadItems(this.extraFilterInput)
      },
    },
  },
  methods: {
    viewOrderDetails(props) {
      this.$router.push({
        path: `/user/store/order/${props.id}`,
      })
    },
    createOrderDispute(props) {
      this.orderHistoryProps.currentServiceForDispute = props
      this.orderHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    hideDisputeModal() {
      this.orderHistoryProps.viewDisputeModal = false
    },
    getDisputeStatus() {
      // showLoader()
      // this.orderHistoryProps.disputeObject = {}
      // const { userOrderDisputeStatus } = useUserUi()
      // userOrderDisputeStatus(this.orderHistoryProps.currentServiceForDispute.id).then(({ data }) => {
      //   if (data.statusCode === '200') {
      //     this.orderHistoryProps.disputeObject = data.responseData
      //   }
      //   hideLoader()
      // })
    },
    submitServiceDispute() {
      // const formData = new FormData()
      // formData.append('referral_email', '')
      // formData.append('dispute_type', 'user')
      // formData.append('dispute_name', this.orderHistoryProps.currentDispute)
      // formData.append('comments', this.orderHistoryProps.currentDispute === 'Others' ? this.orderHistoryProps.disputeReason : '')
      // formData.append('id', this.orderHistoryProps.currentServiceForDispute.id)
      // formData.append('user_id', this.orderHistoryProps.currentServiceForDispute.user_id)
      // formData.append('store_id', this.orderHistoryProps.currentServiceForDispute.store_id)
      // formData.append('provider_id', this.orderHistoryProps.currentServiceForDispute.provider_id)
      // const { createOrderDispute } = useUserUi()
      // showLoader()
      // createOrderDispute(formData)
      //   .then(({ data }) => {
      //     if (data.statusCode === '200') {
      //       showSuccessNotification(this, data.message)
      //     } else {
      //       showDangerNotification(this, data.message)
      //     }
      //     hideLoader()
      //   })
      //   .catch(error => {
      //     showErrorNotification(this, error)
      //   })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}

#create-dispute-modal .modal-body {
  height: 260px;
}

#create-dispute-modal.height-auto .modal-body {
  height: auto;
}

.order-filters-position {
  position: absolute;
  z-index: 9;
  left: 150px;
  top: 20px;
}
@media only screen and (max-width: 767px) {
  .order-filters-position {
    position: static;
  }
}
</style>
